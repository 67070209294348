<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import moment from 'moment'
import { getAction } from '@/command/netTool'
import { giveTypeList } from '@/utils/textFile'

export default {
  name: 'weChatMoneyReceive',
  data() {
    return {
      ...api.command.getState(),
      dataObj: {
        giveNum: 0,
        receivedRate: 0,
        receivedTotal: 0,
        usedRate: 0,
        usedTotal: 0,
        weekReceivedTotal: 0,
        weekUsedTotal: 0
      }
    }
  },
  mounted() {
    const { id, turntableId } = this.$route.query
    if (id || turntableId) {
      api.command.getList.call(this, {
        url: '/redEnvelopInfo/page',
        current: 1,
        paramsValue: {
          redId: id ? id : '',
          turntableId: turntableId ? turntableId : ''
        }
      })
      getAction(`/redEnvelopInfo/stat?redId=${id ? id : ''}&turntableId=${turntableId ? turntableId : ''}`).then(
        result => {
          let dataArr = Object.keys(result.data)
          let entriesArr = Object.values(result.data)
          dataArr.forEach((e, i) => {
            this.dataObj[e] = entriesArr[i] ? entriesArr[i] : 0
          })
          this.$forceUpdate()
        }
      )
    }
  },
  methods: {
    renderTop() {
      const data = [
        {
          name: '累计发放（元）',
          value: `${this.dataObj.sendPrice ?? 0}`
        },
        {
          name: '总金额（元）',
          value: `${this.dataObj.allPrice ?? 0}`
        },
        {
          name: '领取次数',
          value: `${this.dataObj.redNum ?? 0}`
        },
        {
          name: '发放百分比',
          value: `${this.dataObj.ratio ?? 0}`
        }
      ]
      return (
        <div class="home-top">
          {data.map(e => {
            return (
              <div class="home-top-item" onClick={() => e.onClick()}>
                <div>{e.value}</div>
                <div>{e.name}</div>
              </div>
            )
          })}
        </div>
      )
    },
    getHeader() {
      return [
        {
          name: '领取人',
          key: 'name',
          type: 'input'
        },
        {
          name: '联系方式',
          key: 'linkPhone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      let _this = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'userName',
          title: '领取人',
          type: 'lt-150',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                {records.headImg && <img src={records.headImg} />}
                <span>{text}</span>
              </div>
            )
          }
        },
        {
          dataIndex: 'linkPhone',
          title: '联系方式',
          type: 'lt-100'
        },
        {
          dataIndex: 'payment',
          title: '领取方式',
          type: 'lt-100'
        },

        {
          dataIndex: 'price',
          title: '领取金额',
          type: 'lt-100',
          sorter: (a, b) => a.price - b.price
        },
        {
          dataIndex: 'lastPrice',
          title: '累计领取金额',
          type: 'lt-100',
          sorter: (a, b) => a.lastPrice - b.lastPrice
        },
        {
          dataIndex: 'sendTime',
          title: '领取时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.sendTime).unix() - moment(b.sendTime).unix()
        }
      ]
    },
    getButton() {
      return []
    }
  },
  render() {
    return (
      <div>
        {this.renderTop()}
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          records={this.records}
          button={this.getButton()}
        />
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.home-top {
  display: flex;
  align-items: center;
  height: 101px;
  width: 100%;
  background: white;
  margin: 10px 0;
  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    &:not(:last-child)::after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 1px;
      background: rgb(229, 231, 233);
      content: '';
    }
  }
}
/deep/.home-top-item {
  div {
    &:nth-of-type(2) {
      font-size: 14px;
      color: #515a6e;
    }
  }
}
</style>
